






















































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import RegularExpressionFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/RegularExpressionFilterHandlerModule/RegularExpressionFilterHandlerModuleBuilder";
import HandleUserBioField from "@/includes/logic/Modules/components/filters/fields/HandleUserBioField.vue";

import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'
import RegexpValidatorContainer from '@/components/RegexpValidatorContainer.vue';

@Component({
  components: {
    HandleUserBioField,
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    ChipsInput,
    SwitchInput,
    RegexpValidatorContainer
  },
})
export default class RegularExpressionFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: RegularExpressionFilterHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
