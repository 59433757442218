



















import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import RegexpValidator from 'piramis-base-components/src/plugins/AdminTemplate/components/regexp-validator/RegexpValidator.vue';

import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    RegexpValidator,
    Icon
  }
})
export default class RegexpValidatorContainer extends Vue {
  isModalVisible = false
}
